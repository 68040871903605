import * as React from 'react'
import styled from '@emotion/styled'
import { fonts } from '../styles/variables'
import Page from '../components/Page'
import IndexLayout from '../layouts'
import logoFile from '../assets/images/logo.svg'

const IndexContent = styled.div`
  text-align: center;
`
const IndexPageContainer = styled(Page)`
  align-items: center;
  display: flex;
  justify-content: center;
`

const IndexHeading = styled.h1`
  border-top: 1px solid #333;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
`

const IndexBody = styled.p`
  font-family: ${fonts.monospace};
`

const IndexPage = () => (
  <IndexLayout>
    <IndexPageContainer>
      <IndexContent>
        <img src={logoFile} alt="The logo for Tom stones" width="120px" />
        <IndexHeading>Tom Stones</IndexHeading>
        <IndexBody>A Melbourne based full stack dev.</IndexBody>
      </IndexContent>
    </IndexPageContainer>
  </IndexLayout>
)

export default IndexPage
